<template>
  <div>
    <div class="staff">
      <div class="right-top" style="height:100px;">
        <span style="float: left;font-size: 16px; font-weight: 600">{{ KeyWords }}</span>
        <!-- <el-button type="primary">提交</el-button> -->
       
        <el-button
          type="primary"
          @click="addPage"
          style="float: right; margin-right: 115px; margin-bottom: 5px"
          >新增</el-button
        >
      </div>
      <!-- :data="tableData" -->
      <!-- :data="PageConfigure" -->
      <el-table
        border
        :data="PageConfigure"
        row-key="BannerArticleManagementId"
        style="width: 100%"
      >
        <el-table-column type="index" label="排序" width="130" align="center">
        </el-table-column>
        <el-table-column label="文章" align="center">
          <template slot-scope="scope">
            <a :href="`${scope.row.ArticleUrl}`" target="_blank"
              ><span>{{ scope.row.ArticleName }}</span></a
            >
          </template>
        </el-table-column>
        <el-table-column label="序号" width="130" align="center">
          <template slot-scope="scope">
            <span style="font-weight: 700">{{ scope.row.SortNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column width="250" label="操作" align="center">
          <template slot-scope="scope">
            <span
              class="backSpan"
              @click="eduit(scope.row)"
              style="color: #409eff"
            >
              编辑
            </span>
            <span
              class="backSpan"
              @click="PreDelet(scope.row)"
              style="color: #cc0000"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>
      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog
          :visible.sync="dialogFormVisible"
          custom-class="edit-form"
          :title="dialongtitle"
          :modal-append-to-body="false"
        >
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item
              label="文章名称:"
              :label-width="formLabelWidth"
              prop="ArticleName"
            >
               <el-input
                v-model="Rowcontent.ArticleName"
                placeholder="请输入文章名称"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="文章链接:"
              :label-width="formLabelWidth"
              prop="ArticleUrl"
            >
              <el-input
                v-model="Rowcontent.ArticleUrl"
                placeholder="请输入文章链接"
                type="textarea"
                :rows="5"
                maxlength="200"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="文章序号:"
              type="number"
              :label-width="formLabelWidth"
              prop="SortNumber"
            >
               <el-input
                v-model="Rowcontent.SortNumber"
                placeholder="请输入文章序号"
                maxlength="40"
              ></el-input>
            </el-form-item>
          </el-form>
              
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">返回</el-button>
            <el-button @click="submitForm('editForm')" :disabled="isDisabled">
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";

export default {
  inject: ["reload"],
  props: {
    KeyWords: {
      type: String,
      default: String,
    },
  },
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^\+?[1-9][0-9]*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    };
    var urlRules = (rule, value, callback) => {
      // const url = "1renshi";
      const url =".html"
      if (value.search(url) !== -1) {
        callback();
      } else {
        callback(new Error("请输入正确的网址"));
      }
    };
    return {
      dialogFormVisible: false,
      dialongtitle: "",
      formLabelWidth: "100px",
      Rowcontent: {
        ArticleName: "",
        ArticleUrl: "",
        SortNumber: "",
        BannerArticleManagementId: " ",
        KeyWords: this.KeyWords,
      },
      rules: {
        ArticleName: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        ArticleUrl: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: urlRules, trigger: "blur" },
        ],
        SortNumber: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" },
        ],
      },

      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 0,
        TotalPage: 0,
        KeyWords: this.KeyWords,
      },
      IsApp: false,
      IsEdit: false,
      pageconfigID: "",
      pageName: "",
      deleteStatus: false,
      //解决重复点击问题
      isDisabled: false,
      first: true,
    };
  },
  watch: {
    KeyWords(newValue, oldValue) {},
  },
  mounted() {
    // this.rowDrop();
    this.searchDate();
  },
  methods: {
    //获取数据
    searchDate() {
      var _this = this;
      this.$get(this.$api.GetList, {
        ...this.Params,
      }).then((res) => {
        //console.log(res);
        this.PageConfigure = res.Data;
        //console.log(this.PageConfigure);
        this.Params.TotalCount = res.TotalNumber;
        this.Params.TotalPage = res.TotalPage;
      });
    },

    //新增
    addPage() {
      this.IsEdit = false;
      this.dialongtitle = "新增文章";
      this.dialogFormVisible = true;
      this.Rowcontent.ArticleName = "";
      this.Rowcontent.ArticleUrl = "";
      this.Rowcontent.SortNumber = "";
      this.Rowcontent.BannerArticleManagementId = "";
    },
    //编辑
    eduit(val) {
      this.IsEdit = true;
      this.dialongtitle = "编辑文章";
      this.dialogFormVisible = true;
      console.log(val);
      this.Rowcontent.ArticleName = val.ArticleName;
      this.Rowcontent.ArticleUrl = val.ArticleUrl;
      this.Rowcontent.SortNumber = val.SortNumber;
      this.Rowcontent.BannerArticleManagementId = val.BannerArticleManagementId;
    },

    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },

    submitForm(editForm) {
      this.isDisabled = true;
      this.entime();
      var _this = this;
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            console.log(this.Rowcontent);
            this.$post(this.$api.UpdateModel, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          } else {
            console.log("新增");
            console.log(this.Rowcontent);
            this.$post(this.$api.AddModel, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },

    // 行拖拽
    rowDrop() {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.PageConfigure.splice(oldIndex, 1)[0];
          _this.PageConfigure.splice(newIndex, 0, currRow);
          console.log("拖拽完成");
          console.log(_this.PageConfigure);
          _this
            .$post(_this.$api.UpdateSortnumber, _this.PageConfigure)
            .then((res) => {
              if (res.IsSuccess) {
                _this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.searchDate();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.searchDate();
    },

    //删除
    PreDelet(row) {
      this.$confirm("确认删除？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      })
        .then(() => {
          this.$post(
            this.$api.DeleteModel + "?id=" + row.BannerArticleManagementId
          )
            .then((res) => {
              if (res.IsSuccess) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //校验
  },
};
</script>

<style lang="stylus" scoped>
background #fff, /deep/.el-tabs__content {
  // top 50px
  // max-height calc(100% -50px);
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  .backSpan {
    padding: 0px 30px;
    cursor: pointer;
  }

  .right-top {
    margin-bottom: 10px;

    button {
      margin-left: 10px;
    }
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }
}
</style>